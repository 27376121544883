import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { RolesAuth, AuthService } from '../../../../services/auth/auth.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  rol: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/home', title: 'Home', icon: 'ni-tv-2 text-primary', class: '', rol: null },
  { path: '/empresa', title: 'Datos empresa', icon: 'ni-badge text-green', class: '', rol: "empresa" },
  { path: '/empleados', title: 'Empleados', icon: 'ni-circle-08 text-green', class: '', rol: "empresa" },
  { path: '/nominas', title: 'Declaraciones juradas', icon: 'ni-money-coins text-orange', class: '', rol: "empresa" },
  { path: '/admin/empresas', title: 'Empresas', icon: 'ni-badge text-green', class: '', rol: "administrador" },
  { path: '/admin/cargar-dat', title: 'Importar .DAT', icon: 'ni-folder-17 text-green', class: '', rol: "administrador" },
  // { path: '/roles', title: 'Roles', icon: 'ni-key-25 text-info', class: '', rol: "Consulta de roles" },
  // { path: '/existencia', title: 'Consulta existencia', icon: 'fas fa-table', class: '', rol: "Consulta de existencia" },
  // { path: '/proveedor', title: 'Consulta proveedor',  icon:'fas fa-table', class: '', funcion: ["Consulta de proveedor"] },
  // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
  // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
  // { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
  // { path: '/login', title: 'Login',  icon:'', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() emitEvent:EventEmitter<boolean> = new EventEmitter<boolean>();

  public menuItems: any[];


  constructor(private router: Router,
    private _authorize: RolesAuth,
    private _authService: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {});
  }

  showOption(rol) {
    return this._authorize.authorize(rol)
  }

  logout(){
    this._authService.logout();
  }
}
