import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component'

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  public isCollapsed = false;
  public windowDesk = false;

  constructor(private router: Router,) {}
  @ViewChild('sidebar', {static: true}) private sidebar: SidebarComponent;


  ngOnInit() {
    this.sidebar.emitEvent
    .subscribe(
      res =>
      {
        if(res && window.innerWidth > 575){
          this.isCollapsed = true
        }else{
          this.isCollapsed = false;
        }
      }
    );
  }


}
