import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../common/constants.service';
import * as moment from "moment";
import { JsonPipe } from '@angular/common';

import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';



@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private apiUrl = ConstantsService.API_ENDPOINT;
  constructor(private _http: HttpClient,
    private _router: Router
    ) {    
  }

  unlockUser(usuario): Observable<any> {
    return this._http.post(`${this.apiUrl}/auth/unlock?userId=${usuario.id}`,'');
  }

  login(login): Observable <any> {
    return this._http.post
                        (`${this.apiUrl}/auth/login`, 
                        login)
  }

  setSession(authResult) {
    const expiresAt = moment().add(authResult.expires_in,'second');

    sessionStorage.setItem('access_token', authResult.access_token);
    sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );

    sessionStorage.setItem("rol", authResult.rol);

    sessionStorage.setItem("idEmpresa", authResult.idEmpresa ? authResult.idEmpresa : '');
    sessionStorage.setItem('nombreEmpresa', authResult.nombreEmpresa ? authResult.nombreEmpresa : '');
    sessionStorage.setItem('cuitEmpresa', authResult.cuitEmpresa ? authResult.cuitEmpresa : '');
    sessionStorage.setItem("cantEmpleados", authResult.cantEmpleados ? authResult.cantEmpleados : '');
  }          

  logout() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("expires_at");

    sessionStorage.removeItem("rol");

    sessionStorage.removeItem("idEmpresa");
    sessionStorage.removeItem("nombreEmpresa");
    sessionStorage.removeItem("cuitEmpresa");
    sessionStorage.removeItem("cantEmpleados");
    
    this._router.navigate(['/login'])
  }

  public isLoggedIn() {
      return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
      return !this.isLoggedIn();
  }

  getExpiration() {
      const expiration = sessionStorage.getItem("expires_at");
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
  }

  goToLogin() {
    if (sessionStorage.length == 0 || this.isLoggedOut()) {
      this.logout();
      this._router.navigate(['/login']);
    }
  }

  sendMail(email): Observable<any> {
    return this._http.post(`${this.apiUrl}/auth/sendPasswordResetLink`,email);
  }

  changePassword(formReset): Observable<any> {
    return this._http.post(`${this.apiUrl}/auth/resetPassword`,formReset);
  }

}

export class RolesAuth implements CanActivateChild {
  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.authorize(route.data.roles)
  }

  authorize(rol){
    var userRol = sessionStorage.getItem("rol") ? sessionStorage.getItem("rol") : null
    if (rol) {
      if (userRol) {
        let canUse = false;
            if (rol === userRol || rol === "") { canUse = true }
        return canUse
      }
      else {
        return false
      }
    }
    else {
      return true;
    }
  }
}

